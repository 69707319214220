import React from "react";
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Navigate,
} from "react-router-dom";
import VehicleRegistration from "./pages/VehicleRegistration/VehicleRegistration";
import UserPortal from "./pages/UserPortal/UserPortal";
import Login from "./pages/Login/Login";
import InformVehicleOwner from "./pages/InformVehicleOwner/InformVehicleOwner";
import TermsAndConditions from "./components/termsandconditions/TermsAndConditions";
import AboutUs from "./pages/AboutUs/aboutus";

function MainApp() {
	return (
		<Router>
			<Routes>
				<Route path="/" element={<Navigate to="/user-portal" />} />
				{/* Default route to UserPortal */}
				<Route path="/:uid" element={<VehicleRegistration />} />
				<Route path="/login" element={<Login />} />
				<Route path="/user-portal" element={<UserPortal />} />
				<Route
					path="/inform-vehicle-owner/:uid"
					element={<InformVehicleOwner />}
				/>
				<Route
					path="/terms-and-conditions"
					element={<TermsAndConditions />}
				/>
				<Route path="/about-us" element={<AboutUs />} />
			</Routes>
		</Router>
	);
}

export default MainApp;
