import React from "react";
import {
	Container,
	Box,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles"; // Add this import
import theme from "../../assets/theme"; // Ensure this is your theme import

const AboutUs = () => {
	return (
		<ThemeProvider theme={theme}>
			<Container maxWidth="sm" sx={{ paddingBottom: "100px" }}>
				{/* <Box textAlign="center" mb={3}>
					<Typography variant="h4" color="primary">
						Syfr.live
					</Typography>
				</Box> */}

				<Typography variant="h3" color="primary" gutterBottom>
					About Us
				</Typography>

				<Typography variant="body1" paragraph>
					Welcome to Syfr.live! We are dedicated to enhancing vehicle
					safety and communication between vehicle owners and the
					public. Our innovative platform empowers individuals to
					report any mishaps they observe with vehicles, ensuring that
					owners are informed promptly.
				</Typography>

				<Typography variant="h6" gutterBottom>
					Our Mission
				</Typography>
				<Typography variant="body1" paragraph>
					To provide a seamless way for vehicle owners to stay
					connected with their vehicles and the community. We believe
					that proactive communication can lead to safer roads and a
					more responsible driving culture.
				</Typography>

				<Typography variant="h6" gutterBottom>
					How It Works
				</Typography>
				<Typography variant="body1" paragraph>
					Our platform operates through a simple yet effective
					process:
				</Typography>
				<ol>
					<li>
						<strong>Stickers for Vehicles:</strong> Vehicle owners
						acquire our specially designed stickers to place on
						their vehicles.
					</li>
					<li>
						<strong>Report Issues:</strong> Individuals can report
						mishaps such as:
						<ul>
							<li>Accidents</li>
							<li>Doors left open</li>
							<li>Baby inside</li>
						</ul>
					</li>
					<li>
						<strong>Direct Communication:</strong> Reports can be
						made through text messages or phone calls, allowing for
						immediate contact with the vehicle owner.
					</li>
					<li>
						<strong>Spam Protection:</strong> Our platform
						implements measures to limit spam, ensuring genuine
						communication.
					</li>
				</ol>

				<Typography variant="h6" gutterBottom>
					Why Choose Syfr.live?
				</Typography>
				<TableContainer component={Paper}>
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<strong>Feature</strong>
								</TableCell>
								<TableCell>
									<strong>Benefit</strong>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<TableRow>
								<TableCell>Community-Oriented</TableCell>
								<TableCell>
									We foster a culture of looking out for one
									another.
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>User-Friendly</TableCell>
								<TableCell>
									Our platform is intuitive and easy to use
									for all users.
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Safety First</TableCell>
								<TableCell>
									Enhanced communication contributes to a
									safer environment.
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>

				<Typography variant="h6" gutterBottom>
					Join Us
				</Typography>
				<Typography variant="body1" paragraph>
					Join us in our mission to enhance vehicle safety and ensure
					that no one is left in the dark regarding vehicle issues.
					Together, we can make a difference!
				</Typography>

				<Typography variant="body1" paragraph>
					Thank you for choosing Syfr.live. Together, we can make our
					roads safer and more responsible.
				</Typography>
			</Container>
		</ThemeProvider>
	);
};

export default AboutUs;
