import React from "react";
import { Box, Typography, Container } from "@mui/material";
import theme from "../../assets/theme"; // Import your MUI theme
import { ThemeProvider } from "@mui/material/styles"; // Wrap with ThemeProvider

const TermsAndConditions = () => {
	return (
		<ThemeProvider theme={theme}>
			<Container maxWidth="md">
				<Box py={5}>
					<Typography variant="h3" color="primary" gutterBottom>
						Terms and Conditions
					</Typography>

					<Typography variant="body1" gutterBottom>
						Welcome to Syfr.live! By using our platform, you agree
						to abide by the following terms and conditions.
					</Typography>

					<Typography variant="h5" gutterBottom>
						1. General Conditions
					</Typography>
					<Typography variant="body1" gutterBottom>
						We reserve the right to refuse service to anyone for any
						reason at any time. You understand that your content
						(not including credit card information), may be
						transferred unencrypted and involve transmissions over
						various networks.
					</Typography>

					<Typography variant="h5" gutterBottom>
						2. Accuracy of Information
					</Typography>
					<Typography variant="body1" gutterBottom>
						We are not responsible if information made available on
						this site is not accurate, complete, or current. The
						material on this site is provided for general
						information only.
					</Typography>

					<Typography variant="h5" gutterBottom>
						3. Prohibited Uses
					</Typography>
					<Typography variant="body1" gutterBottom>
						In addition to other prohibitions as set forth in these
						terms, you are prohibited from using the site or its
						content for any unlawful purpose, to solicit others to
						perform unlawful acts, to violate regulations, or to
						infringe upon intellectual property rights.
					</Typography>

					<Typography variant="h5" gutterBottom>
						4. Changes to Terms and Conditions
					</Typography>
					<Typography variant="body1" gutterBottom>
						We reserve the right to update, change or replace any
						part of these terms and conditions by posting updates or
						changes to our website. It is your responsibility to
						check our website periodically for changes.
					</Typography>

					<Typography variant="body1" gutterBottom>
						If you have any questions regarding these Terms and
						Conditions, please contact us at support@syfr.live.
					</Typography>
				</Box>
			</Container>
		</ThemeProvider>
	);
};

export default TermsAndConditions;
