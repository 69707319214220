import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
	TextField,
	Button,
	Container,
	Box,
	Typography,
	Grid,
	Card,
	CardActionArea,
	Snackbar,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import MuiAlert from "@mui/material/Alert";
import theme from "../../assets/theme";
import Lottie from "react-lottie";
import callingAnimation from "../../assets/callinganim.json";
import MessageIcon from "@mui/icons-material/Message";
import ParkingIcon from "@mui/icons-material/LocalParking";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import CarCrashIcon from "@mui/icons-material/CarCrash";
import ErrorIcon from "@mui/icons-material/ErrorOutline";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import CallIcon from "@mui/icons-material/Call";
import ShieldIcon from "@mui/icons-material/Shield";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import InfoIcon from "@mui/icons-material/Info";

const InformVehicleOwner = () => {
	const [isTooltipOpen, setIsTooltipOpen] = useState(false);

	const navigate = useNavigate(); // Initialize navigate function
	const { uid } = useParams();
	const [formData, setFormData] = useState({
		reason: "WRONG_PARKING",
		latitude: null,
		longitude: null,
		isContactOwner: true,
		isEmergency: false,
		requireCallback: true,
		vehicleRegistrationDigits: "",
		uidString: uid,
		informerContact: "",
	});
	const [notification, setNotification] = useState({
		show: false,
		message: "",
		success: true,
	});
	const [showAnimation, setShowAnimation] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [phoneError, setPhoneError] = useState(false);
	const [isClicked, setIsClicked] = useState(false);

	const reasonSectionRef = useRef(null); // Create a ref for the reason section

	useEffect(() => {
		// Scroll to the reason section when the component mounts
		if (reasonSectionRef.current) {
			reasonSectionRef.current.scrollIntoView({
				behavior: "smooth",
				block: "start",
			});
		}
	}, []);

	const handleReasonChange = (reason) => {
		setFormData((prevData) => ({
			...prevData,
			reason: reason,
		}));
	};

	const handlePhoneChange = (e) => {
		const value = e.target.value.slice(0, 10);
		setPhoneNumber(value);
		if (/^\d{10}$/.test(value)) {
			setPhoneError(false);
		} else {
			setPhoneError(true);
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const updatedFormData = { ...formData, isContactOwner: false };
		setFormData(updatedFormData);
		await submitForm(updatedFormData);
	};

	const handlePopupSubmit = async () => {
		setIsClicked(true);
		if (!phoneNumber || phoneError) {
			setPhoneError(true);
			setIsClicked(false);
			return;
		}

		const updatedFormData = {
			...formData,
			isContactOwner: true,
			informerContact: `+91${phoneNumber}`,
		};
		setFormData(updatedFormData);

		await submitForm(updatedFormData);
		setTimeout(() => {
			setIsClicked(false);
		}, 1000);
	};

	const submitForm = async (data) => {
		if (data.isContactOwner) {
			setShowAnimation(true);
		}
		try {
			const response = await fetch(
				`${process.env.REACT_APP_API_URL}/vehicle/inform`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify(data),
				}
			);

			if (response.ok) {
				const responseData = await response.json();
				setNotification({
					show: true,
					message: "Message sent successfully",
					success: true,
				});

				// Automatically open dial pad and call maskedOwnerContact
				if (responseData.maskedOwnerContact) {
					window.location.href = `tel:${responseData.maskedOwnerContact}`;
				}
			} else {
				const data = await response.json();
				setNotification({
					show: true,
					message: data.message || "Error informing vehicle owner",
					success: false,
				});
			}
		} catch (error) {
			setNotification({
				show: true,
				message: "Network error",
				success: false,
			});
		} finally {
			setShowAnimation(false);
			setTimeout(() => {
				setNotification((prev) => ({ ...prev, show: false }));
			}, 3000);
		}
	};

	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData: callingAnimation,
		rendererSettings: {
			preserveAspectRatio: "xMidYMid slice",
		},
	};

	const reasonCards = [
		{
			label: "Wrong Parking",
			value: "WRONG_PARKING",
			icon: <ParkingIcon />,
		},
		{ label: "Lights On", value: "LIGHTS_ON", icon: <LightbulbIcon /> },
		{
			label: "Door Unlocked",
			value: "DOOR_UNLOCKED",
			icon: <LockOpenIcon />,
		},
		{
			label: <Typography style={{ color: "red" }}>Accident</Typography>,
			value: "ACCIDENT",
			icon: <CarCrashIcon style={{ color: "red" }} />,
		},
		{
			label: (
				<Typography style={{ color: "red" }}>Baby Inside</Typography>
			),
			value: "BABY_INSIDE",
			icon: <ChildFriendlyIcon style={{ color: "red" }} />,
		},

		{ label: "Other Issue", value: "OTHER", icon: <ErrorIcon /> },
	];

	const Alert = React.forwardRef(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
	});

	return (
		<ThemeProvider theme={theme}>
			<Container
				maxWidth="sm"
				sx={{ overflowY: "auto", paddingBottom: "100px" }}
			>
				<Box textAlign="center" mb={3}>
					<Typography variant="h4" color="primary">
						Syfr.live
					</Typography>
				</Box>
				<Box
					display="flex"
					justifyContent="space-between"
					alignItems="center"
					mb={3}
				>
					<Typography
						variant="h5"
						gutterBottom
						style={{ fontWeight: "bold" }}
						color="secondary"
					>
						Want to contact owner
					</Typography>

					<IconButton
						edge="end"
						aria-label="shield"
						sx={{
							color: "#388e3c",
							padding: "8px", // Professional padding around the icon
						}}
						onClick={() => navigate("/user-portal")} // Navigate to User Portal on click
					>
						<ShieldIcon />
					</IconButton>
				</Box>

				<Typography
					variant="h5"
					gutterBottom
					sx={{
						color: "#757575",
						fontSize: "14px",
						marginBottom: "18px",
					}}
				>
					Select the issue(s) to notify the owner:
				</Typography>
				<div ref={reasonSectionRef}>
					{" "}
					{/* Add ref here */}
					<Grid container spacing={2}>
						{reasonCards.map((card) => (
							<Grid item xs={6} key={card.value}>
								<Card
									sx={{
										border:
											formData.reason === card.value
												? "2px solid #388e3c"
												: "2px solid transparent",
										boxShadow:
											"0px 3px 6px rgba(0,0,0,0.16)",
										height: "100%",
									}}
								>
									<CardActionArea
										onClick={() =>
											handleReasonChange(card.value)
										}
										sx={{
											display: "flex",
											flexDirection: "column",
											justifyContent: "center",
											alignItems: "center",
											height: "100%",
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												padding: "20px",
											}}
										>
											{card.icon}
											<Typography
												variant="body1"
												sx={{
													marginTop: "8px",
													fontWeight: "bold",
												}}
											>
												{card.label}
											</Typography>
										</Box>
									</CardActionArea>
								</Card>
							</Grid>
						))}
					</Grid>
				</div>

				<Grid container spacing={3} mt={2}>
					<Grid item xs={12}>
						<Box
							sx={{
								position: "relative",
								display: "flex",
								alignItems: "center",
							}}
						>
							<TextField
								fullWidth
								label="Vehicle Registration Digits"
								id="vehicleRegistrationDigits"
								name="vehicleRegistrationDigits"
								value={formData.vehicleRegistrationDigits}
								onChange={(e) =>
									setFormData({
										...formData,
										vehicleRegistrationDigits:
											e.target.value,
									})
								}
								required
								inputProps={{
									style: {
										textTransform: "uppercase",
										letterSpacing: "0.9em",
									},
									pattern: "[A-Z0-9]*",
									maxLength: 3,
								}}
							/>

							<Tooltip
								title={
									<Box
										sx={{
											width: 200,
											maxWidth: "100%",
											p: 1,
										}}
									>
										<img
											src="https://i.ibb.co/MkbSVBV/Frame-9image.png"
											alt="Vehicle Registration Example"
											style={{
												width: "100%",
												height: "auto",
											}}
										/>
									</Box>
								}
								placement="right"
								arrow
								open={isTooltipOpen}
								onOpen={() => setIsTooltipOpen(true)}
								onClose={() => setIsTooltipOpen(false)}
								sx={{
									position: "absolute",
									top: "50%",
									right: "0",
									transform: "translateY(-50%)",
									maxWidth: 250, // Limit Tooltip width
								}}
							>
								<IconButton
									aria-label="info"
									sx={{
										position: "absolute",
										top: "50%",
										right: 0,
										transform: "translateY(-50%)",
										zIndex: 1,
									}}
									onTouchStart={() => setIsTooltipOpen(true)} // Trigger tooltip on tap for mobile
									onMouseDown={() => setIsTooltipOpen(true)} // Trigger tooltip for desktop click
								>
									<InfoIcon />
								</IconButton>
							</Tooltip>
						</Box>

						<Typography
							variant="body2"
							sx={{
								color: "#757575",
								fontSize: "12px",
								marginTop: "8px",
								letterSpacing: "0.5px", // Adjust spacing between letters
							}}
						>
							Enter any three digits of Vehicle Registration
							Number
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<TextField
							fullWidth
							label="Your phone no."
							id="phoneNumber"
							name="phoneNumber"
							value={phoneNumber}
							onChange={handlePhoneChange}
							error={phoneError}
							type="number"
							helperText={
								phoneError
									? "Please enter a valid 10-digit phone number"
									: ""
							}
							sx={{
								marginBottom: "16px",
							}}
							InputProps={{
								startAdornment: (
									<Typography variant="h6" sx={{ mr: 1 }}>
										+91
									</Typography>
								),
								inputMode: "numeric", // Open numeric keypad
								pattern: "[0-9]*", // Restrict to numbers only
							}}
						/>
					</Grid>
				</Grid>
			</Container>
			<Box
				sx={{
					position: "fixed",
					bottom: 0,
					left: 0,
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					backgroundColor: "#ffffff",
					padding: "0px",
					boxShadow: "0px -2px 10px rgba(0,0,0,0.1)",
				}}
			>
				<Button
					variant="contained"
					color="primary"
					type="submit"
					onClick={handleSubmit}
					sx={{
						width: "50%",
						height: "60px",
						backgroundColor: isClicked ? "#8BC34A" : "#388e3c", // Change background color based on isClicked
						fontSize: "16px",
						fontWeight: "bold",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: "8px",
						borderRadius: 0,
						pointerEvents: isClicked ? "none" : "auto", // Disable button interaction when clicked
					}}
				>
					Send Text <MessageIcon />
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={handlePopupSubmit}
					disableRipple
					sx={{
						width: "50%",
						height: "60px",
						fontSize: "16px",
						fontWeight: "bold",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						gap: "8px",
						borderRadius: 0,
						border: "2px solid #4CAF50",
						color: isClicked ? "#FFFFFF" : "#4CAF50", // Green text color
						backgroundColor: isClicked ? "#388E3C" : "#FFFFFF", // Change background color based on isClicked
						boxShadow: isClicked
							? "none"
							: "0px 4px 8px rgba(0,0,0,0.2)", // Remove shadow when clicked
						"&:hover": {
							backgroundColor: isClicked ? "#388E3C" : "#FFFFFF", // Darker green background on click
							color: isClicked ? "#FFFFFF" : "#4CAF50", // White text color on click
							// "& .MuiSvgIcon-root": {
							// 	color: "#FFFFFF", // White icon color on hover
							// },
						},
						"&:active": {
							backgroundColor: isClicked ? "#388E3C" : "#FFFFFF", // Darker green background on click
							color: isClicked ? "#FFFFFF" : "#4CAF50", // White text color on click
							// "& .MuiSvgIcon-root": {
							// 	color: "#FFFFFF", // White icon color on click
							// },
						},
					}}
				>
					Call Owner <CallIcon />
				</Button>
			</Box>
			<Snackbar
				open={notification.show}
				autoHideDuration={3000}
				onClose={() =>
					setNotification((prev) => ({ ...prev, show: false }))
				}
				anchorOrigin={{ vertical: "top", horizontal: "center" }} // Show notification at the top
			>
				<Alert
					onClose={() =>
						setNotification((prev) => ({ ...prev, show: false }))
					}
					severity={notification.success ? "success" : "error"}
				>
					{notification.message}
				</Alert>
			</Snackbar>
			{showAnimation && (
				<Box
					sx={{
						position: "fixed",
						top: 0,
						left: 0,
						width: "100%",
						height: "100%",
						backgroundColor: "rgba(255,255,255,0.5)",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						zIndex: 9999,
					}}
				>
					<Lottie options={defaultOptions} height={200} width={200} />
				</Box>
			)}
		</ThemeProvider>
	);
};

export default InformVehicleOwner;
